import { AfterViewInit, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from "@angular/core";
import { ModelViewerElement } from "@google/model-viewer/lib/model-viewer";

@Component({
	selector: "arcade-three-d",
	templateUrl: "./arcade-three-d.component.html",
	styleUrls: ["./arcade-three-d.component.scss"],
})
export class ArcadeThreeDComponent implements AfterViewInit, OnChanges {
	@ViewChild("wrapper") wrapperRef: ElementRef;
	modelViewer: ModelViewerElement;
	// modelviewer library atrributes
	@Input() alt = "Metaquarium 3D";
	@Input() autoRotate?: boolean = false;
	@Input() autoRotateDelay?: number = 0;
	@Input() autoRotateReversed?: boolean = false;
	@Input() autoRotateSpeed?: number;
	@Input() backgroundColor?: string;
	@Input() cameraControls = false;
	@Input() cameraOrbit?: string = "auto auto auto";
	@Input() cameraTarget?: string;
	@Input() colorGamut?: string;
	@Input() exposure?: number;
	@Input() fieldOfView?: string;
	@Input() iar?: boolean;
	@Input() lightIntensity?: number;
	@Input() minFieldOfView?: number;
	@Input() reveal?: string;
	@Input() shadowIntensity?: number;
	@Input() src: string;
	@Input() theme?: string;
	@Input() type?: string;
	@Input() upAxis?: string;
	@Input() poster?: string;
	@Input() posterAlt?: string;
	@Input() disableTap?: boolean;
	@Input() disableZoom?: boolean;
	@Input() disablePan?: boolean;
	@Input() interactionPrompt?: string;
	@Input() interactionPromptStyle?: string;
	@Input() autoPlay?: boolean;

	// custom
	@Input() size?: number;
	@Input() visible = true;
	@Input() glass = false;
	@Input() autoSize = false;
	@Input() scale?: string;
	@Input() name?: string;
	@Input() glassOnly = false;

	showModel = false;
	modelSrc: string;
	fallbackSrc = "assets/objects/bowl.glb";
	fallbackSize = 120;
	height = this.size;
	width = "100%";
	initialized = false;

	constructor() {
		if (!this.visible) {
			this.visible = true;
		}

		// this.getModelSrc();
	}

	getModelSrc() {
		if (!this.src || this.src === "") {
			this.modelSrc = this.fallbackSrc;
			// this.visible = false;
		} else {
			this.modelSrc = this.src;
		}

		return this.modelSrc;
	}

	ngAfterViewInit() {
		if (!this.size) {
			this.height = this.fallbackSize;
			this.width = "100%";
		} else {
			this.height = this.size;
		}

		this.loadModel();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (this.initialized) {
			this.updateModelViewer();
		}
	}

	loadModel() {
		this.createModelViewer();
	}

	updateModelViewer() {
		if (!this.visible && !this.modelViewer.modelIsVisible) {
			this.dismissPoster();
		}

		if (this.modelViewer.getAnimations().length > 0) {
			this.modelViewer.setAttribute("autoplay", "");
		}

		this.modelViewer.setAttribute("src", this.getModelSrc());
		this.modelViewer.setAttribute("alt", this.alt);
	}

	inspect(_event) {
		// list to events and attributes
		const getAnimations = this.modelViewer.getAnimations();

		const maximumFieldOfView = this.modelViewer.getMaximumFieldOfView();
		const minimumFieldOfView = this.modelViewer.getMinimumFieldOfView();

		const getIdealAspect = this.modelViewer.getIdealAspect();

		const getCameraOrbit = this.modelViewer.getCameraOrbit();

		const getCameraTarget = this.modelViewer.getCameraTarget();

		const getCameraFieldOfView = this.modelViewer.getFieldOfView();

		const getDimensions = this.modelViewer.getDimensions();

		const getBoundingBoxCenter = this.modelViewer.getBoundingBoxCenter();
	}

	createModelViewer() {
		// Create a new Model Viewer element

		this.modelViewer = new ModelViewerElement();
		if (this.name) {
			this.modelViewer.setAttribute("name", this.name);
			this.modelViewer.setAttribute("id", this.name);
		}

		if (this.visible === false) {
			this.modelViewer.setAttribute("reveal", "manual");
		}

		this.modelViewer.setAttribute("src", this.getModelSrc());
		this.modelViewer.classList.add("arcade-model-3d");

		if (this.scale) {
			this.modelViewer.scale = this.scale;
		}

		this.modelViewer.setAttribute("alt", this.alt);
		if (!this.autoSize) {
			this.modelViewer.style.width = this.height + "px";
			this.modelViewer.style.height = this.height + "px";
		} else {
			this.modelViewer.style.height = this.size + "px";
			const width = document.getElementById("loaderWrapper")?.offsetWidth;
			this.modelViewer.style.width = width + "px";
		}

		if (this.autoRotate) {
			this.modelViewer.setAttribute("auto-rotate", "");
		}

		if (this.disableTap) {
			this.modelViewer.setAttribute("disable-tap", "");
		}

		if (this.disablePan) {
			this.modelViewer.setAttribute("disable-pan", "");
		}

		if (this.interactionPrompt) {
			this.modelViewer.setAttribute("interaction-prompt", this.interactionPrompt);
		}

		if (this.interactionPromptStyle) {
			this.modelViewer.setAttribute("interaction-prompt-style", this.interactionPromptStyle);
		}

		// this.modelViewer.setAttribute("ar", "");
		// this.modelViewer.setAttribute("ar-modes", "webxr scene-viewer");
		// this.modelViewer.setAttribute("ar-scale", "fixed");
		this.modelViewer.setAttribute("bounds", "tight");
		if (this.cameraOrbit) {
			this.modelViewer.setAttribute("camera-orbit", this.cameraOrbit);
		} else {
			this.modelViewer.setAttribute("camera-orbit", "auto auto auto");
		}

		if (this.cameraTarget) {
			this.modelViewer.setAttribute("camera-target", this.cameraTarget);
		} else {
			this.modelViewer.setAttribute("camera-target", "auto auto auto");
		}

		if (this.disableZoom) {
			this.modelViewer.setAttribute("disable-zoom", "");
		}

		// this.modelViewer.setAttribute("interaction-prompt-style", "basic");
		this.modelViewer.setAttribute("rotation-per-second", "240%");
		this.modelViewer.setAttribute("environment-image", "neutral");
		if (this.shadowIntensity) {
			this.modelViewer.setAttribute("shadow-intensity", this.shadowIntensity.toString());
		} else {
			this.modelViewer.setAttribute("shadow-intensity", "1");
		}

		if (this.cameraControls) {
			this.modelViewer.setAttribute("camera-controls", "");
		}

		if (this.poster) {
			this.modelViewer.setAttribute("poster", this.poster);
		}

		if (this.autoPlay) {
			this.modelViewer.setAttribute("autoplay", "");
		}

		// Append the Model Viewer element to the body
		this.wrapperRef.nativeElement.appendChild(this.modelViewer);
		this.initialized = true;
	}

	public dismissPoster() {
		this.modelViewer.dismissPoster();
	}
}
